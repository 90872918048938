import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
// import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
import VueSplide from "@splidejs/vue-splide";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import Axios from "axios";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "./assets/image/css/landing.css";
import "./assets/image/css/admin.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import 'vue-search-select/dist/VueSearchSelect.css'
import { VueReCaptcha } from "vue-recaptcha-v3";
import 'leaflet/dist/leaflet.css';
// API service init
// ApiService.init();

// Remove this to disable mock API
// MockService.init();

Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

// router.beforeEach((to, from, next) => {
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   store.dispatch(RESET_LAYOUT_CONFIG);
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    var roleuser = null

    setTimeout(() => {
      if(localStorage.getItem('token')){
        roleuser = JSON.parse(localStorage.getItem('user')).roles
        if(to.meta.role == 'all') {
          next()
        } else {
          var b = to.meta.role.find(val => {
            return val === roleuser[0];
          })
          if(b){
            next()
          } else {
            next('/dashboard')
          }
        }
      } else {
        next('/')
      }
    }, 1000)
      
  } else {
    next();
  }

    

    // if (store.getters.isLoggedIn) {
      // next();
    //   return;
    // }
    // next("/login");
  // } else {
  //   next();
  // }
});
// Initiate Vue Recaptcha
Vue.use(VueReCaptcha, { 
  siteKey: `${process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY}`,
  loaderOptions: {autoHideBadge: true}
});
Vue.use(require('vue-moment'));

new Vue({
  router,
  store,
  i18n,
  vuetify,
  VueSplide,
  render: h => h(App)
}).$mount("#app");
