import Vue from "vue";
import Router from "vue-router";
import PortalVue from "portal-vue";

import constParams from "./params";

Vue.use(PortalVue);
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),

      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            requiresAuth: true,
            role: 'all'
          },
        },
        {
          path: "/konten",
          name: "konten",
          component: () => import("@/view/pages/LihatSemua.vue"),
          meta: {
            requiresAuth: true,
            role: 'all'
          },
        }, 
        {
          path: "/pelaporan",
          name: "pelaporan",
          component: () =>
            import("@/view/pages/pelaporan-kegiatan/Pelaporan.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.PIC]
            },
        },
        {
          path: "/permohonan",
          name: "permohonan",
          component: () => import("@/view/pages/permohonan/index.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/permohonan/form",
          name: "permohonan",
          component: () => import("@/view/pages/permohonan/permohonan.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/permohonan/form/filling",
          name: "permohonan",
          component: () => import("@/view/pages/permohonan/form.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/perubahan-profil",
          name: "perubahan-profil",
          component: () => import("@/view/pages/permohonan/PerubahanProfil.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/draft",
          name: "draft",
          component: () => import("@/view/pages/draft/Draft.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/pelaporan/riwayat",
          name: "riwayat",
          component: () => import("@/view/pages/riwayat/riwayat.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/ormas",
          name: "Ormas",
          component: () => import("@/view/pages/wizard/Wizard-2.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC]
          },
        },
        {
          path: "/profile",
          name: "Informasi",
          component: () =>
            import("@/view/pages/profile-ormas/InformasiOrmas.vue"),
          meta: {
            requiresAuth: true,
            role: [constParams.PIC, constParams.SUPERADMIN]
          },
          children: [
            {
              path: "data-organisasi",
              name: "organisasi",
              component: () => import("@/view/pages/profile-ormas/index.vue"),
              meta: {
                requiresAuth: true,
                role: [constParams.PIC, constParams.SUPERADMIN]
              },
            },
            {
              path: "cabang-organisasi",
              name: "cabangorganisasi",
              component: () => import("@/view/pages/cabang-ormas/index.vue"),
              meta: {
                requiresAuth: true,
                role: [constParams.PIC, constParams.SUPERADMIN]
              },
            },
            
          ]
        },
        {
          path: "/info-ormas/domisili",
          name: "Domisili",
          component: () =>
            import("@/view/pages/data-organisasi/domisili/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/info-ormas/nasional",
          name: "Nasional",
          component: () =>
            import("@/view/pages/data-organisasi/nasional/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM, constParams.ASING]
            },
        },
        {
          path: "/info-ormas/rekap-organisasi",
          name: "Rekap",
          component: () =>
            import("@/view/pages/data-organisasi/rekap/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM, constParams.ASING]
            },
        },
        {
          path: "/rekap-organisasi",
          name: "Rekap org",
          component: () =>
            import("@/view/pages/data-organisasi/rekap/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM, constParams.ASING]
            },
        },
        {
          path: "/data-organisasi/rekap-organisasi",
          name: "Rekap data",
          component: () =>
            import("@/view/pages/data-organisasi/rekap/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM, constParams.ASING]
            },
        },
        {
          path: "/pengaturan/pengguna/rekap-organisasi",
          name: "Rekap pengguna",
          component: () =>
            import("@/view/pages/data-organisasi/rekap/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/pengaturan/datamaster/rekap-organisasi",
          name: "Rekap datamater",
          component: () =>
            import("@/view/pages/data-organisasi/rekap/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/tata-kelola/rekap-organisasi",
          name: "Rekap tata kelola",
          component: () =>
            import("@/view/pages/data-organisasi/rekap/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/tata-kelola/permohonan",
          name: "Permohonan",
          component: () =>
            import("@/view/pages/tata-kelola/persetujuan/permohonan/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN,constParams.POLPUM]
            },
        },
        {
          path: "/tata-kelola/cabang-terdaftar",
          name: "Cabang Terdaftar",
          component: () =>
            import("@/view/pages/tata-kelola/persetujuan/cabang-terdaftar/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/tata-kelola/cabang-lapor",
          name: "Cabang Lapor",
          component: () =>
            import("@/view/pages/tata-kelola/persetujuan/cabang-lapor/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/tata-kelola/sengketa",
          name: "Sengketa",
          component: () =>
            import("@/view/pages/tata-kelola/sengketa/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM, constParams.ASING]
            },
        },
        {
          path: "/tata-kelola/tambah-ormas",
          name: "Tambah Ormas",
          component: () =>
            import("@/view/pages/tata-kelola/tambah-ormas/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.KESBANGPOL, constParams.SUPERADMIN, constParams.POLPUM]
            },
        },
        {
          path: "/tata-kelola/tambah-ormas-asing",
          name: "Tambah Ormas Asing",
          component: () =>
            import("@/view/pages/tata-kelola/tambah-ormas-asing/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN, constParams.ASING, constParams.KESBANGPOL]
            },
        },
        {
          path: "/formdataormas",
          name: "formormas",
          component: () =>
            import("@/view/pages/form-data-ormas/index.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.PIC]
            },
        },

        // ADMIN TERRITORY
        {
          path: "/pengaturan/konten/kategori",
          name: "Pengaturan Kategori",
          component: () =>
            import("@/view/pages/pengaturan/konten/kategori.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/konten/cms",
          name: "Pengaturan CMS",
          component: () =>
            import("@/view/pages/pengaturan/konten/cms.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/pengguna/tambah-pengguna",
          name: "Tambah Pengguna",
          component: () =>
            import("@/view/pages/pengaturan/user/addUser.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/pengguna/instansi",
          name: "Kelola Instansi",
          component: () =>
            import("@/view/pages/pengaturan/user/instansi.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/pengguna/hakakses",
          name: "Kelola Hak Akses",
          component: () =>
            import("@/view/pages/pengaturan/user/hakakses.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/pengguna/pembekuan",
          name: "Kelola Pembekuan Ormas",
          component: () =>
            import("@/view/pages/pengaturan/user/pembekuan.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/pengguna/usertype",
          name: "Kelola Usertype",
          component: () =>
            import("@/view/pages/pengaturan/user/userType.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/datamaster/cirikhusus",
          name: "Data Master Ciri Khusus",
          component: () =>
            import("@/view/pages/pengaturan/data-master/cirikhusus.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/datamaster/bidangkegiatan",
          name: "Data Master Bidang Kegiatan",
          component: () =>
            import("@/view/pages/pengaturan/data-master/bidangkegiatan.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/datamaster/kegiatan",
          name: "Data Master Kegiatan",
          component: () =>
            import("@/view/pages/pengaturan/data-master/kegiatan.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/datamaster/jenissekretariat",
          name: "Data Master Sekretariat",
          component: () =>
            import("@/view/pages/pengaturan/data-master/sekretariat.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
        {
          path: "/pengaturan/datamaster/wilayah",
          name: "Data Master Wilayah",
          component: () =>
            import("@/view/pages/pengaturan/data-master/wilayah.vue"),
            meta: {
              requiresAuth: true,
              role: [constParams.SUPERADMIN]
            },
        },
      ]
    },
    {
      path: "/polpum",
      name: "login-kesbangpol",
      component: () => import("@/view/pages/kesbangpol/login.vue"),
    },
    {
      path: "/polpum/resetsandi",
      name: "reset-kesbangpol",
      component: () => import("@/view/pages/kesbangpol/reset-password.vue"),
    },
    {
      path: "/login-callback",
      name: "login-sso",
      component: () => import("@/view/pages/sso/loginSso.vue"),
    },
    {
      path: "/",
      name: "landing",
      component: () => import("@/view/pages/landing/landing"),
      children: [
        {
          path: "login",
          name: "login",
          component: () => import("@/view/pages/landing/login/Modal.vue"),
          props: {
            component: () => import("@/view/pages/landing/login/LoginFloat.vue")
          }
        },
        {
          path: "aktivasi",
          component: () =>
            import("@/view/pages/landing/login/ModalAktivasi.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/ActivationFloat.vue")
          }
        },
        {
          path: "lupapassword",
          component: () => import("@/view/pages/landing/login/Modal.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/ForgotPasswordFloat.vue")
          }
        },
        {
          path: "buatsandi",
          component: () => import("@/view/pages/landing/login/Modal.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/BuatPassword.vue")
          }
        },
        {
          path: "resetsandi",
          component: () => import("@/view/pages/landing/login/Modal.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/ResetPassword.vue")
          }
        },
        {
          path: "perbaharuikatasandi",
          component: () => import("@/view/pages/landing/login/Modal.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/PerbaharuiPassword.vue")
          }
        },
        {
          path: "/result/:keyword",
          component: () => import("@/view/pages/landing/login/ModalResult.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/ResultFloat.vue")
          }
        },
        {
          path: "detail",
          name: "detail",
          component: () => import("@/view/pages/landing/login/ModalResult.vue"),
          props: {
            component: () =>
              import("@/view/pages/landing/login/DetailFloat.vue")
          }
        }
      ]
    },
    // {
    //   path: "/adm",
    //   redirect: "/adm",
    //   component: () => import("@/view/pages/auth/login_pages/Login-1"),
    //   children: [
    //     {
    //       name: "adm",
    //       path: "/adm",
    //       component: () => import("@/view/pages/auth/login_pages/Login-1")
    //     }
    //   ]
    // },
    {
      path: "/resetpass",
      redirect: "/resetpass",
      component: () => import("@/view/pages/auth/Reset"),
      children: [
        {
          name: "resetpass",
          path: "/resetpass",
          component: () => import("@/view/pages/auth/Reset")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
