/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

// import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import loading from "./loading.module";
import axios from "axios";

import ConstActivity from "../../../act_constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    profileOrg: {},
    founderOrg: {},
    structureOrg: {},
    documentOrg: {},
    bankAccountOrg: {},
    branchOrg: {},
    branchDetail: {},
    pelaporanS1: {},
    pelaporanS2: {},
  },
  modules: {
    // auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    loading
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    profile_org(state,data) {
      state.profileOrg = data;
    },
    founder_org(state,data) {
      state.founderOrg = data;
    },
    structure_org(state,data) {
      state.structureOrg = data;
    },
    document_org(state,data) {
      state.documentOrg = data;
    },
    bank_account_org(state,data) {
      state.bankAccountOrg = data;
    },
    branch_org(state, data) {
      state.branchOrg = data
    },
    branch_detail(state, data) {
      state.branchDetail = data
    },
    pelaporan_s1(state, data) {
      state.pelaporanS1 = data
    },
    pelaporan_s2(state, data) {
      state.pelaporanS2 = data
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    errorHandler(state,code) {
      if(code == 401 || code == 403) {
        localStorage.clear()
        location.reload()
      }
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    nameUserLogin: state => state.user
  },
  actions: {
    login({ commit }, user) {
      let baseURL =
        process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/login";

      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: baseURL,
          data: user,
          method: "POST"
        })
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.username;
            for(var i = 0; i < resp.data.menus.length; i++){
              var menu = resp.data.menus[i].items
              if(menu.length > 0) {
                resp.data.menus[i].collapse = false
                var submenu = menu
                  for(var b = 0; b < submenu.length; b++ ){
                    if(submenu[b].items.length > 0){
                      submenu[b].collapse = false
                    }
                  }
              }
            }
            localStorage.setItem("user", JSON.stringify(resp.data))
            localStorage.setItem("token", token);
            // Add the following line:
            axios.defaults.headers.common["Authorization"] = "Bearer ${token}";
            commit("auth_success", token, user);
            resolve(resp);

            // Store activity log
            if (resp.data.id) {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_LOGIN,
                "user_id": resp.data.id.toString()
              });
            }
          })
          .catch(err => {
            // if (err.response) commit("errorHandlerLogin", err.response.status);
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    loginSso({ commit }, user) {
      let baseURL =
        process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/validate-sso";
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: baseURL,
          data: user,
          method: "POST"
        })
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.username;
            for(var i = 0; i < resp.data.menus.length; i++){
              var menu = resp.data.menus[i].items
              if(menu.length > 0) {
                resp.data.menus[i].collapse = false
                var submenu = menu
                  for(var b = 0; b < submenu.length; b++ ){
                    if(submenu[b].items.length > 0){
                      submenu[b].collapse = false
                    }
                  }
              }
            }
            localStorage.setItem("user", JSON.stringify(resp.data))
            localStorage.setItem("token", token);
            // Add the following line:
            axios.defaults.headers.common["Authorization"] = "Bearer ${token}";
            commit("auth_success", token, user);
            resolve(resp);

            // Store activity log
            if (resp.data.id) {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_LOGIN,
                "user_id": resp.data.id.toString()
              });
            }
          })
          .catch(err => {
            // if (err.response) commit("errorHandlerLogin", err.response.status);
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    getProfile({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/profile";
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            localStorage.setItem('profile', JSON.stringify(resp.data.data))
            if(resp.data.data.dataOrmas) localStorage.setItem('idOrmas', JSON.stringify(resp.data.data.dataOrmas.id))

            // Set global profile info
            this.dispatch("updateUserAccountInfo", resp.data.data);
            this.dispatch("updateUserPersonalInfo", resp.data.data.profilUser);
            this.dispatch("updateUserRoleInfo", resp.data.data.roleUsers);

            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "http://localhost:8001/api/v1/users/create",
          data: user,
          method: "POST"
        })
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem("token", token);
            // Add the following line:
            axios.defaults.headers.common["Authorization"] = "Bearer ${token}";
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    aktivasiAkun({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/aktivasi",
          data: user,
          method: "POST"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    lupaPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/lupa-kata-sandi",
          data: user,
          method: "POST"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    submitLupaPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/lupa-kata-sandi/ubah-sandi/${data.token}`,
          data: data.form,
          method: "POST"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    validasiTokenLupas({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/lupa-kata-sandi/validasi-token/${token}`,
          data: token,
          method: "GET"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    pencarianData({ commit }, itemsearch) {
      let params2 = {
          offset: 0,
          limit: 10,
          skip: 0,
          order: "id",
          where: {
            nama_ormas: { ilike: `%${itemsearch}%` }
          },
          include: [
            {
              relation: "bidangKegiatans",
              scope: {
                include: [{ relation: "masterJenisOrmas" }]
              }
            },
            { relation: "masterLokasiSekretariat" },
          ]
        };
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas?filter=" + encodeURIComponent(JSON.stringify(params2)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          // headers: {
          //   Authorization: 'Bearer ' + localStorage.getItem('token')
          // },
          // params: params2
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    detailPencarianData({ commit }, uid) {
      // let params2 = {
      //     offset: 0,
      //     limit: 100,
      //     skip: 0,
      //     order: "id",
      //     where: {
      //       nama_ormas: { ilike: `%${itemsearch}%` }
      //     },
      //     include: [
      //       {
      //         relation: "bidangKegiatans",
      //         scope: {
      //           include: [{ relation: "masterJenisOrmas" }]
      //         }
      //       },
      //       { relation: "masterLokasiSekretariat" },
      //     ]
      //   };
      let params2 = {
        "include": [
          "masterProvinsi", "masterKabupatenKota", "masterLokasiSekretariat", "masterCiriKhusus",
          {"relation": "bidangKegiatans", "scope": {"include": [{"relation": "masterJenisOrmas"}]}}
        ]
      }
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas/ " + uid +"?filter=" + encodeURIComponent(JSON.stringify(params2)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          // headers: {
          //   Authorization: 'Bearer ' + localStorage.getItem('token')
          // },
          // params: params2
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getProfileOrganization({ commit }, skt) {
      let getnoSKT = null
      if(skt) getnoSKT = skt
      else getnoSKT = JSON.parse(localStorage.getItem('user')).username
      let params2 = {
        "where": {"no_skt": getnoSKT},
        "include": 
        [
          {
            "relation": "bidangKegiatans",
            "scope": {
              "include": [{"relation": "masterJenisOrmas"}]
            }
          },
          "tujuanOrmas", "masterProvinsi", "masterKabupatenKota", "masterCiriKhusus","masterLokasiSekretariat"
        ]
      }

      // let params = null
      // if(skt){
      //   params = '/' + skt
      // } else {
      //   params = "?filter=" + encodeURIComponent(JSON.stringify(params2))
      // }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/informasi-ormas?filter=" + encodeURIComponent(JSON.stringify(params2));
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            commit("profile_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getFounderOrganization({ commit }, orgid) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/informasi-ormas/info-pendirian/" + parseInt(orgid);
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("founder_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getStructureOrganization({ commit }, orgid) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/informasi-ormas/struktur-organisasi/" + orgid;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("structure_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDocumentOrganization({ commit }, orgid) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/informasi-ormas/dokumen/" + orgid;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("document_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getBankAccountOrganization({ commit }, orgid) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/informasi-ormas/rekening/" + orgid;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("bank_account_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListCabang({ commit }, idparam) {
      var ormasid = ''
      if(idparam !== null){
        ormasid = idparam
      } else {
        if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
        else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      }      
      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "order": "id",
        "where": {
          "data_ormas_id": parseInt(ormasid)
        },
        "include": ["dataOrmas", "wilayahProvinsi", "wilayahKabupaten", "masterLokasiSekretariat"]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("branch_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListCabangAll({ commit }, idparam) {
      var ormasid = ''
      if(idparam !== null){
        ormasid = idparam
      } else {
        if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
        else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      }      
      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "order": "id",
        "where": {
          "data_ormas_id": parseInt(ormasid)
        },
        "include": ["dataOrmas", "wilayahProvinsi", "wilayahKabupaten", "masterLokasiSekretariat"]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi?all=1&filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("branch_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDetailCabang ({ commit }, branchid) {
      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "include": ["dataOrmas", "wilayahProvinsi", "wilayahKabupaten", "masterLokasiSekretariat", "masterJenisRekening"]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi/" + branchid + "?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("branch_detail", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getPelaporanS1({ commit }, userid) {
      var d = new Date();
      var n = d.getFullYear();
      var ormasid = ''
      if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
      else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "where": {
          "and": [
            {"tanggal_kegiatan": {"gte": n + "-01-01"}},
            {"tanggal_kegiatan": {"lte": n + "-06-30"}}
          ],
          "data_ormas_id": parseInt(ormasid)
        },
        "include": ["masterJenisKegiatan", "masterSumberDana"]
      }
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPelaporan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    patchPelaporan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deletePelaporan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    detailLaporan({ commit }, id) {
      let params = {
        "include": ["masterJenisKegiatan", "masterSumberDana"]
       }
       
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan/" + id + "?filter=" + encodeURIComponent(JSON.stringify(params)) 
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getPelaporanS2({ commit }, userid) {
      var d = new Date();
      var n = d.getFullYear();
      var ormasid = ''
      if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
      else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "where": {
          "and": [
            {"tanggal_kegiatan": {"gte": n + "-07-01"}},
            {"tanggal_kegiatan": {"lte": n + "-12-31"}}
          ],
          "data_ormas_id": parseInt(ormasid)
        },
        "include": ["masterJenisKegiatan", "masterSumberDana"]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            commit("pelaporan_s2", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getPelaporanRiwayat({ commit }, userid) {
      var d = new Date();
      var n = d.getFullYear();
      var ormasid = ''
      if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
      else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id

      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "where": {
          "data_ormas_id": parseInt(ormasid)
        },
        "include": ["masterJenisKegiatan", "masterSumberDana"]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s2", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getPropinsi({ commit }) {
       
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah/list"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getKabupatenKota ({ commit }, id) {
       
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah/list/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getWilayahId ({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah/list/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getJenisKegiatan({ commit }) {
      let params = {
        "where": {"terhapus": false},
        "fields": {
          "terhapus": false
        }
      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-kegiatan?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getSumberDana({ commit }) {
      let params = {
        "where": {"terhapus": false},
        "fields": {
          "terhapus": false
        }
      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/sumber-dana?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getStatusSekretariat({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat" ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListNegara({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/negara" ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getStatusSekretariatOrmas({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat" ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          params: {
            filter: {
              where: {namaLokasi: {nilike: "%pusat%"}}
            }
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getCiriKhusus({ commit }) {
       
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/ciri-khusus"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getBidangKegiatan({ commit }) {
       
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-ormas"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getJabatan({ commit }) {
       
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jabatan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getJenisRekening({ commit }) {
       
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-rekening"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },

    getBadanOtonom({ commit }) {
      var ormasid = ''
      if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
      if(JSON.parse(localStorage.getItem('profile')).dataOrmas) ormasid = JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      let params = {
        "where": {
          "id": {"neq": ormasid ? parseInt(ormasid) : 0}
          }
      }
      if(ormasid == '') delete params.where
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas/list?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListOrmas({ commit }, mode) {
      let params = {}
      if(mode){
        if(mode.role == 'kabupaten')params = {where: {kabupaten_kota_sekretariat: mode.kode + ''}}
        if(mode.role == 'provinsi') params = {where: {provinsi_sekretariat: mode.kode + ''}}
        // else params = {"where": {"id": {"neq": 0}}}
      }else {
        params = {
          "where": {
            "id": {"neq": 0}
          }
        }
      } 
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas/list?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    exportExcel ({ commit }) {
      var ormasid = ''
      if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
      else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan/export-excel/" + ormasid ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    exportPDF ({ commit }) {
      var ormasid = ''
      if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
      else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan/export-pdf/" + ormasid ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataOrmas ({ commit }) {
      let ormasid = JSON.parse(localStorage.getItem('user'))
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/integrasi-data-ormas?noSkt=" + encodeURIComponent(ormasid.username);
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },

    submitKelengkapanProfil({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/profil"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKelengkapanInfoPendiri({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/info-pendirian"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKelengkapanStruktur({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/struktur-organisasi"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKelengkapanRekening({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/rekening"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKelengkapanCabang({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/cabang-ormas"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKelengkapanCabangPatch({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/cabang-ormas/"+ form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKelengkapan({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/ajukan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          // data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteCabangKelengkapan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kelengkapan-data/cabang-ormas/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    submitPerubahanProfil({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + form[1] + "/profil"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPerubahanStruktur({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + form[1] + "/struktur-organisasi"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPerubahanRekening({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + form[1] + "/rekening"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listPermohonanPerubahan({ commit }, id) {
      var ormasid = ''
      if(id) {
        ormasid = id
      }else {
        if(localStorage.getItem('idOrmas')) ormasid = localStorage.getItem('idOrmas')
        else ormasid= JSON.parse(localStorage.getItem('profile')).dataOrmas.id
      }
      
      var f = {"where": {"dataOrmasId": parseInt(ormasid)}}
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan?filter=" + encodeURIComponent(JSON.stringify(f)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getJenisPerubahan({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-perubahan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitJenisPerubahan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataPerubahan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPermohonanCabang({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/"+ form[1] + "/cabang-ormas"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPermohonanCabangPatch({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + form[2] + "/cabang-ormas/"+ form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteCabangPermohonan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + id[0] + "/cabang-ormas/" + id[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    submitPermohonan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + id + "/ajukan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          // data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDetailCabangPermohonan ({ commit }, branchid) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/permohonan-perubahan/" + branchid[0] + "/cabang-ormas/" + branchid[1]
      // ?filter= + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("branch_detail", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printPDFCabang({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi/export-pdf/" + id 
      // ?filter= + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printPDFInfoOrg({ commit },id) {
      var idormas = ''
      if(id) idormas = id
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/informasi-ormas/export-pdf/" + idormas
      // ?filter= + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printPDFInfoOrganisasi({ commit }, q) {
      let params = {}
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
        params = {
          "where": {
          },
          "include":[
            {
              "relation":"bidangKegiatan",
              "scope":{
                "include":[
                  {"relation": "masterJenisOrmas"}
                ]
              }
            },
            "masterCiriKhusus",
            "wilayahProvinsi",
            "wilayahKabupatenKota",
            "masterLokasiSekretariat"
          ]
        }
      // }
      if(q && q.bk && q.bk != '') params.where.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.where.mLokasiSekretariatId = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.where.mCiriKhususId =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.where.namaOrmas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.where.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.sk && q.sk != '') params.where.statusKegiatan = q.sk ? q.sk === 'true' ? true : false : ''
      if(q && q.ds && q.ds != '') params.where.isSengketa = q.ds ? q.ds === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.where.provinsi = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.where.kabupaten_kota = q.kab ? q.kab : ''
      if(q && q.sa && q.sa != '') params.where.sktAktif = q.sa ? q.sa : ''
      if(q && q.tahun_ter_skt && q.tahun_ter_skt != '') params.where.and = [{tanggalSkt: {gte: `${q.tahun_ter_skt}-01-01`}}, {tanggalSkt: {lte: `${q.tahun_ter_skt}-12-31`}}]
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi/export-pdf?filter=" + encodeURIComponent(JSON.stringify(params)) + "&exportType=" + q.exporttype
      // ?filter= + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printExcelInfoOrganisasi({ commit }, q) {
      let params = {}
      
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
        params = {
          "where": {
          },
          "include":[
            {
              "relation":"bidangKegiatan",
              "scope":{
                "include":[
                  {"relation": "masterJenisOrmas"}
                ]
              }
            },
            "masterCiriKhusus",
            "wilayahProvinsi",
            "wilayahKabupatenKota",
            "masterLokasiSekretariat"
          ]
        }
      // }
      if(q && q.bk && q.bk != '') params.where.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.where.mLokasiSekretariatId = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.where.mCiriKhususId =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.where.namaOrmas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.where.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.sk && q.sk != '') params.where.statusKegiatan = q.sk ? q.sk === 'true' ? true : false : ''
      if(q && q.ds && q.ds != '') params.where.isSengketa = q.ds ? q.ds === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.where.provinsi = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.where.kabupaten_kota = q.kab ? q.kab : ''
      if(q && q.sa && q.sa != '') params.where.sktAktif = q.sa ? q.sa : ''
      if(q && q.tahun_ter_skt && q.tahun_ter_skt != '') params.where.and = [{tanggalSkt: {gte: `${q.tahun_ter_skt}-01-01`}}, {tanggalSkt: {lte: `${q.tahun_ter_skt}-12-31`}}]

      // if(q.prov) params.where.provinsi = q.prov
      // if(q.kabkot) params.where.kabupaten_kota = q.kabkot
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi/export-excel?filter=" + encodeURIComponent(JSON.stringify(params)) + "&exportType=" + q.exporttype
      // ?filter= + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printPDFDetailUnreg({ commit }, id) {
      var idormas = ''
      if(id) idormas = id
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/export-pdf/" + idormas
      // ?filter= + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printPDFAsing({ commit }, q) {
      let type = 'type=&';
      let params = {
        where: {},
        include: [
          "asingAnggotas", "masterNegara", "asingKemitraan", "asingPelaporans", "asingKemitraanLkpds",
          {
            relation: "asingLokuses", 
            scope: {
              include: [
                {relation: "masterProvinsi"},
                {relation: "masterKabupatenKota"}, 
                {relation: "masterKelurahan"}, 
                {relation: "masterKecamatan"}
              ]
            }
          }
        ]
      }

      if (q && q.no && q.no != '') {
        params.where.or = [
          {namaOrmas: {"ilike": "%" + q.no + "%"}},
          {nomorMsp: {"ilike": "%" + q.no + "%"}}
        ];
      }

      if (q && q.type) {
        type = `type=${q.type}&`;
      }

      let baseURL = `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/ormas-asing/export/pdf?${type}&filter=${encodeURIComponent(JSON.stringify(params))}`;

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printExcelAsing({ commit }, q) {
      let type = 'all=0&';
      let params = {
        where: {},
        include: [
          "asingAnggotas", "masterNegara", "asingKemitraan", "asingPelaporans", "asingKemitraanLkpds",
          {
            relation: "asingLokuses", 
            scope: {
              include: [
                {relation: "masterProvinsi"},
                {relation: "masterKabupatenKota"}, 
                {relation: "masterKelurahan"}, 
                {relation: "masterKecamatan"}
              ]
            }
          }
        ]
      }

      if (q && q.no && q.no != '') {
        params.where.or = [
          {namaOrmas: {"ilike": "%" + q.no + "%"}},
          {nomorMsp: {"ilike": "%" + q.no + "%"}}
        ];
      }

      if (q && q.type) {
        type = `all=${q.type}&`;
      }

      let baseURL = `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/ormas-asing/export/excel?${type}&filter=${encodeURIComponent(JSON.stringify(params))}`;

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataOrganisasiCount({ commit }, q) {
      let params = {}
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
      // }
      if(q && q.bk && q.bk != '') params.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.mLokasiSekretariatId = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.mCiriKhususId =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.namaOrmas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.sk && q.sk != '') params.statusKegiatan = q.sk ? q.sk === 'true' ? true : false : ''
      if(q && q.ds && q.ds != '') params.isSengketa = q.ds ? q.ds === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.provinsi = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.kabupaten_kota = q.kab ? q.kab : ''
      if(q && q.sa && q.sa != '') params.sktAktif = q.sa ? q.sa : ''
      if(q && q.tahun_ter_skt && q.tahun_ter_skt != '') params.and = [{tanggalSkt: {gte: `${q.tahun_ter_skt}-01-01`}}, {tanggalSkt: {lte: `${q.tahun_ter_skt}-12-31`}}]

      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi/count?where=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataOrganisasiAllCount({ commit }, q) {
      let params = {}
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
      // }
      if(q && q.bk && q.bk != '') params.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.mLokasiSekretariatId = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.mCiriKhususId =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.namaOrmas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.sk && q.sk != '') params.statusKegiatan = q.sk ? q.sk === 'true' ? true : false : ''
      if(q && q.ds && q.ds != '') params.isSengketa = q.ds ? q.ds === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.provinsi = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.kabupaten_kota = q.kab ? q.kab : ''
      if(q && q.sa && q.sa != '') params.sktAktif = q.sa ? q.sa : ''
      if(q && q.tahun_ter_skt && q.tahun_ter_skt != '') params.and = [{tanggalSkt: {gte: `${q.tahun_ter_skt}-01-01`}}, {tanggalSkt: {lte: `${q.tahun_ter_skt}-12-31`}}]

      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi/all/count?where=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataOrganisasiAll({ commit }, q) {
      let params = {}
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
        params = {
          offset: q.offset * 20, 
          limit: 20,
          skip: 0,
          "where": {
          },
          "include":[
            {
              "relation":"bidangKegiatan",
              "scope":{
                "include":[
                  {"relation": "masterJenisOrmas"}
                ]
              }
            },
            "masterCiriKhusus",
            "wilayahProvinsi",
            "wilayahKabupatenKota",
            "masterLokasiSekretariat"
          ]
        }
      // }
      if(q && q.bk && q.bk != '') params.where.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.where.mLokasiSekretariatId = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.where.mCiriKhususId =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.where.namaOrmas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.where.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.sk && q.sk != '') params.where.statusKegiatan = q.sk ? q.sk === 'true' ? true : false : ''
      if(q && q.ds && q.ds != '') params.where.isSengketa = q.ds ? q.ds === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.where.provinsi = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.where.kabupaten_kota = q.kab ? q.kab : ''
      if(q && q.sa && q.sa != '') params.where.sktAktif = q.sa ? q.sa : ''
      if(q && q.tahun_ter_skt && q.tahun_ter_skt != '') params.where.and = [{tanggalSkt: {gte: `${q.tahun_ter_skt}-01-01`}}, {tanggalSkt: {lte: `${q.tahun_ter_skt}-12-31`}}]
      
      // if(q.prov) params.where.provinsi = q.prov
      // if(q.kabkot) params.where.kabupaten_kota = q.kabkot
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi/all?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataOrganisasi({ commit }, q) {
      let params = {}
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
        params = {
          offset: q.offset * 20, 
          limit: 20,
          skip: 0,
          "where": {
          },
          "include":[
            {
              "relation":"bidangKegiatan",
              "scope":{
                "include":[
                  {"relation": "masterJenisOrmas"}
                ]
              }
            },
            "masterCiriKhusus",
            "wilayahProvinsi",
            "wilayahKabupatenKota",
            "masterLokasiSekretariat"
          ]
        }
      // }
      if(q && q.bk && q.bk != '') params.where.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.where.mLokasiSekretariatId = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.where.mCiriKhususId =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.where.namaOrmas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.where.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.sk && q.sk != '') params.where.statusKegiatan = q.sk ? q.sk === 'true' ? true : false : ''
      if(q && q.ds && q.ds != '') params.where.isSengketa = q.ds ? q.ds === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.where.provinsi = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.where.kabupaten_kota = q.kab ? q.kab : ''
      if(q && q.sa && q.sa != '') params.where.sktAktif = q.sa ? q.sa : ''
      if(q && q.tahun_ter_skt && q.tahun_ter_skt != '') params.where.and = [{tanggalSkt: {gte: `${q.tahun_ter_skt}-01-01`}}, {tanggalSkt: {lte: `${q.tahun_ter_skt}-12-31`}}]
      
      // if(q.prov) params.where.provinsi = q.prov
      // if(q.kabkot) params.where.kabupaten_kota = q.kabkot
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDetailDataOrganisasi({ commit }, id) {
      let params = {
        "include":[
          // {
          //   "relation":"bidangKegiatan",
          //   "scope":{
          //     "include":[
          //       {"relation": "masterJenisOrmas"}
          //     ]
          //   }
          // },
          "masterCiriKhusus",
          "wilayahProvinsi",
          "wilayahKabupatenKota",
          "masterLokasiSekretariat"
        ]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi/" + id + "?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getKegiatanOrmas({ commit }, idormas) {
      let params = {"where":{"data_ormas_id": parseInt(idormas)},"include": ["masterJenisKegiatan", "masterSumberDana"]}
      
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pelaporan-kegiatan?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getAfterPerubahan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/list-permohonan/"+ id;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListSengketa({ commit }, q) {
      let params = {
        "offset": 0,
        "limit": 100,
        "order": "id desc",
        "where": {
        },
        "include": [
          {
            "relation": "dataOrmas", 
            "scope": {"include":[{"relation":"masterLokasiSekretariat"}]}
          }
        ]
      }
      if(q.status_sengketa !== '') params.where.status_sengketa = q.status_sengketa
      if(q.tanggal_sengketa !== '') params.where.tanggal_sengketa = q.tanggal_sengketa

      let filter = encodeURIComponent(JSON.stringify(params));
      if (q.search !== '') filter = filter + "&term=" + q.search;

      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas?filter=" + filter ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteSengketa({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/" + id

      let sengketa = this.dispatch("detailSengketa", id)

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Get ormas data from id sengketa, then log activity update dispute status
            sengketa.then(resp => {
              let data = this.dispatch("getDetailDataOrganisasi", resp.data.data.data_ormas_id);
                data.then(resp => {
                  let data = resp.data;
                  
                  this.dispatch("storeActivity", {
                    "activity": ConstActivity.ACT_DISPUTE_DELETE.replace("_ORMAS_", data.namaOrmas),
                    "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
                  });
                });
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    printExcelSengketa({ commit }, q) {
      let params = {
        "offset": 0,
        "limit": 100,
        "order": "id desc",
        "where": {
        },
        "include": [
          {
            "relation": "dataOrmas", 
            "scope": {"include":[{"relation":"masterLokasiSekretariat"}]}
          }
        ]
      }
      if(q.status_sengketa !== '') params.where.status_sengketa = q.status_sengketa
      if(q.tanggal_sengketa !== '') params.where.tanggal_sengketa = q.tanggal_sengketa

      let filter = encodeURIComponent(JSON.stringify(params));
      if (q.search !== '') filter = filter + "&term=" + q.search;

      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/export/"+ q.type +"?filter=" + filter ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    sengketaBerakhir({ commit }, dataform) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/berakhir/" + dataform[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: dataform[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Get ormas data from id sengketa, then log activity update dispute status
            axios({
              url: process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/" + dataform[1],
              method: "GET",
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
            })
              .then(resp => {
                let data = this.dispatch("getDetailDataOrganisasi", resp.data.id);
                data.then(resp => {
                  let data = resp.data;
                  
                  this.dispatch("storeActivity", {
                    "activity": ConstActivity.ACT_DISPUTE_STATUS.replace("_ORMAS_", data.namaOrmas),
                    "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
                  });
                });
              })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    createSengketa({ commit }, dataform) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: dataform,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Get ormas data from id sengketa, then log activity update dispute status
            let data = this.dispatch("getDetailDataOrganisasi", dataform.data_ormas_id);
            data.then(resp => {
              let data = resp.data;
              
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_DISPUTE_ADD.replace("_ORMAS_", data.namaOrmas),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              });
            });
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    detailSengketa({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    patchSengketa({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/sengketa-ormas/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "PATCH",
          data: form[0],
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Get ormas data from id sengketa, then log activity update dispute status
            let data = this.dispatch("getDetailDataOrganisasi", form[0].data_ormas_id);
            data.then(resp => {
              let data = resp.data;
              
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_DISPUTE_UPDATE.replace("_ORMAS_", data.namaOrmas),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              });
            });
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListCabangLapor({ commit }) {
      let params = {
        "offset": 0,
        "limit": 7000,
        "skip": 0,
        "order": "id",
        "include": ["dataOrmas", "wilayahProvinsi", "wilayahKabupaten", "masterLokasiSekretariat",
          {
            "relation":"dataOrmas",
            "scope":{
              "include":[
                {"relation":"masterCiriKhusus"},
                {"relation":"bidangKegiatans", "scope": {"include": ["masterJenisOrmas"]}}
              ]
            }
          }
        ]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi?all=1&filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDataDetailCabangLapor({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    verifikasiCabangLapor({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cabang-organisasi/verifikasi/" + id[0]
      const params = new URLSearchParams()
      params.append('status', id[1].status)
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "POST",
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Get detail cabang by id, and store activity log
            this.dispatch("getDetailCabang", id[0]).then(resp => {
              let status = id[1].status == 1 ? "Diterima" : "Ditolak"
              
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_REPORT_BRANCH.replace("_ORMAS_", resp.data.data.nama_cabang).replace("_STATUS_", status),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              });
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListPermohonanPerubahan({ commit }) {
      let params = {
        "offset": 0,
        "limit": 7000,
        "skip": 0,
        "order": "id desc",
        "include": [
          {"relation": "dataOrmas", "scope": {"include": [{"relation": "masterCiriKhusus"}]}}
        ]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/list-permohonan?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getlistpermohonandetail({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/list-permohonan/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    verifikasiPerubahan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/list-permohonan/verifikasi/" + id[0]
      const params = new URLSearchParams()
      params.append('status', id[1].status)
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "POST",
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Get jenis perubahan, get ormas by id, and store activity log
            this.dispatch("getDataPerubahan", id[0]).then(resp => {
              let status = id[1].status == 1 ? "Diterima" : "Ditolak"
              let data = this.dispatch("getDetailDataOrganisasi", resp.data.data.jenisPerubahan.dataOrmasId);

              data.then(resp => {
                let data = resp.data;
                
                this.dispatch("storeActivity", {
                  "activity": ConstActivity.ACT_CONFIRMATION.replace("_ORMAS_", data.namaOrmas).replace("_STATUS_", status),
                  "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
                });
              });
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    printOutTidakTerdaftar({ commit }, q) {
      let params = {}
      let stat = null
      if(JSON.stringify(q[0]) == '{}'){
        params = {
          "offset": 0,
          "limit": 100,
          "skip": 0,
          "order": "id desc",
          "include": ["masterProvinsi", "masterKabupatenKota", "masterCiriKhusus", "masterLokasiSekretariat"]
        }
        stat = 1
      } else {
        stat = 0
        params = {
          "where": {
          },
          "include":[
            "masterCiriKhusus",
            "masterProvinsi",
            "masterKabupatenKota",
            "masterLokasiSekretariat"
          ]
        }
        if(q[0] && q[0].bk && q[0].bk != '') params.where.bidangKegiatanId = {"inq": q[0].bk ? q[0].bk : ''}
        if(q[0] && q[0].ss && q[0].ss != '') params.where.mLokasiSekretariatId = q[0].ss ? q[0].ss : ''
        if(q[0] && q[0].ck && q[0].ck != '') params.where.mCiriKhususId =  q[0].ck ? q[0].ck : ''
        if(q[0] && q[0].no && q[0].no != '') params.where.nama_ormas = q[0].no ? {"ilike": "%" + q[0].no + "%"} : ''
        if(q[0] && q[0].jo && q[0].jo != '') params.where.isBerjenjang = q[0].jo ? q[0].jo === 'true' ? true : false : ''
        if(q[0] && q[0].sk && q[0].sk != '') params.where.statusKegiatan = q[0].sk ? q[0].sk === 'true' ? true : false : ''
        if(q[0] && q[0].ds && q[0].ds != '') params.where.isSengketa = q[0].ds ? q[0].ds === 'true' ? true : false : ''
        if(q[0] && q[0].prov && q[0].prov != '') params.where.provinsi = q[0].prov ? q[0].prov : ''
        if(q[0] && q[0].kab && q[0].kab != '') params.where.kabupaten_kota = q[0].kab ? q[0].kab : ''
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/export/" + q[1] + "?all=" + stat + "&filter=" + encodeURIComponent(JSON.stringify(params));
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListPenambahanOrmas({ commit }, q) {
      let params = {}
      let type = 'all=1';

      if(JSON.stringify(q) == '{}'){
        params = {
          offset: q.offset * 20, 
          limit: 20,
          skip: 0,
          // "offset": 0,
          // "limit": 20,
          // "skip": 0,
          "order": "id desc",
          "include": ["masterProvinsi", "masterKabupatenKota", "masterCiriKhusus", "masterLokasiSekretariat"]
        }
      } else {
        
        params = {
          offset: q.offset * 20, 
          limit: 20,
          skip: 0,
          "where": {
          },
          "include":[
            "masterCiriKhusus",
            "masterProvinsi",
            "masterKabupatenKota",
            "masterLokasiSekretariat"
          ]
        }
        if(q && q.bk && q.bk != '') params.where.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
        if(q && q.ss && q.ss != '') params.where.m_lokasi_sekretariat_id = q.ss ? q.ss : ''
        if(q && q.ck && q.ck != '') params.where.m_ciri_khusus_id =  q.ck ? q.ck : ''
        if(q && q.no && q.no != '') params.where.nama_ormas = q.no ? {"ilike": "%" + q.no + "%"} : ''
        if(q && q.jo && q.jo != '') params.where.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
        if(q && q.prov && q.prov != '') params.where.provinsi_sekretariat = q.prov ? q.prov : ''
        if(q && q.kab && q.kab != '') params.where.kabupaten_kota_sekretariat = q.kab ? q.kab : ''
        
      }

      if (q.type) {
        type = `all=${q.type}`
      }

      let baseURL = `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/penambahan-ormas?${type}&filter=${encodeURIComponent(JSON.stringify(params))}`;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListPenambahanOrmasCount({ commit }, q) {
      let params = {}
      let type = 'all=1';

      if(q && q.bk && q.bk != '') params.bidangKegiatanId = {"inq": q.bk ? q.bk : ''}
      if(q && q.ss && q.ss != '') params.m_lokasi_sekretariat_id = q.ss ? q.ss : ''
      if(q && q.ck && q.ck != '') params.m_ciri_khusus_id =  q.ck ? q.ck : ''
      if(q && q.no && q.no != '') params.nama_ormas = q.no ? {"ilike": "%" + q.no + "%"} : ''
      if(q && q.jo && q.jo != '') params.isBerjenjang = q.jo ? q.jo === 'true' ? true : false : ''
      if(q && q.prov && q.prov != '') params.provinsi_sekretariat = q.prov ? q.prov : ''
      if(q && q.kab && q.kab != '') params.kabupaten_kota_sekretariat = q.kab ? q.kab : ''

      if (q.type) {
        type = `all=${q.type}`
      }

      let baseURL = `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/penambahan-ormas/count?${type}&where=${encodeURIComponent(JSON.stringify(params))}`
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanOrmasProfil({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity penambahan ormas tidak terdaftar
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_UNREGISTERED_ORMAS_ADD.replace("_ORMAS_", form.dataOrmas.nama_ormas),
              "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    patchPenambahanOrmasProfil({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/" + localStorage.getItem('idPenambahanOrmas')
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "patch",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity penambahan ormas tidak terdaftar
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_UNREGISTERED_ORMAS_UPDATE.replace("_ORMAS_", form.dataOrmas.nama_ormas),
              "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanOrmasPendirian({ commit }, form) {
      let id = localStorage.getItem('idPenambahanOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/info-pendirian/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanOrmasStruktur({ commit }, form) {
      let id = localStorage.getItem('idPenambahanOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/struktur-organisasi/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanOrmasRekening({ commit }, form) {
      let id = localStorage.getItem('idPenambahanOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/rekening/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanOrmasCabang({ commit }, form) {
      let id = localStorage.getItem('idPenambahanOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanCabangPatch({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/"+ form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDetailPenambahanOrmas({ commit }, id) {
      // let params = {
      //   "offset": 0,
      //   "limit": 100,
      //   "skip": 0,
      //   "order": "id desc",
      //   "include": ["masterProvinsi", "masterKabupatenKota", "masterCiriKhusus"]
      // }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/" + id 
      // + "?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          // params: params2
        })
          .then(resp => {
            // commit("pelaporan_s1", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deletePenambahan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/" + id

      let dataPenambahanOrmas = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity penambahan ormas tidak terdaftar
            dataPenambahanOrmas.then(resp => {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_UNREGISTERED_ORMAS_DELETE.replace("_ORMAS_", resp.data.data.nama_ormas),
                "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteCabangPenambahan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListCabangPenambahan({ commit }, id) {
      let params = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "order": "id",
        "include": ["dataOrmas", "wilayahProvinsi", "wilayahKabupaten", "masterLokasiSekretariat"]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/" + id + "?filter=" + encodeURIComponent(JSON.stringify(params))
      // let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/" + id;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("bank_account_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDetailCabangPenambahan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/detail/" + id
      // let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/cabang/" + id;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            commit("bank_account_org", resp);
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPenambahanOrmasAsing({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/ormas-asing"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDetailPenambahanOrmasAsing({ commit }, id) {
      let params = {"include": [{"relation": "asingAnggotas", "scope": {"include": [{"relation": "masterNegara"}]}}, "masterNegara", "asingKemitraan", {"relation": "asingLokuses", "scope": {"include": [{"relation": "masterProvinsi"}, {"relation": "masterKabupatenKota"}, {"relation": "masterKelurahan"}, {"relation": "masterKecamatan"}]}}, "asingPelaporans", "asingKemitraanLkpds"]
        }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/ormas-asing/" + id  + "?filter=" + encodeURIComponent(JSON.stringify(params)) ;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    patchPenambahanOrmasAsingProfil({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/ormas-asing/" + localStorage.getItem('idPenambahanOrmas')
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "patch",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
            // this.dispatch("storeActivity", {
            //   "activity": ConstActivity.ACT_UNREGISTERED_ORMAS_UPDATE.replace("_ORMAS_", form.dataOrmas.nama_ormas),
            //   "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            // })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListPenambahanOrmasAsingCount({ commit }, q) {
      let params = {}
      let type = 'type=';

      if (q && q.no && q.no != '') {
        params.or = [
          {namaOrmas: {"ilike": "%" + q.no + "%"}},
          {nomorMsp: {"ilike": "%" + q.no + "%"}}
        ];
      }

      if (q && q.type) {
        type = `type=${q.type}`;
      }

      let baseURL = `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/ormas-asing/count?${type}&where=${encodeURIComponent(JSON.stringify(params))}` 
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getListPenambahanOrmasAsing({ commit }, q) {
      let type = 'type=&';
      let params = {
        offset: q.offset * 20, limit: 20, skip: 0,
        where: {},
        include: [
          "asingAnggotas", "masterNegara", "asingKemitraan", "asingPelaporans", "asingKemitraanLkpds",
          {
            relation: "asingLokuses", 
            scope: {
              include: [
                {relation: "masterProvinsi"},
                {relation: "masterKabupatenKota"}, 
                {relation: "masterKelurahan"}, 
                {relation: "masterKecamatan"}
              ]
            }
          }
        ]
      }

      if (q && q.no && q.no != '') {
        params.where.or = [
          {namaOrmas: {"ilike": "%" + q.no + "%"}},
          {nomorMsp: {"ilike": "%" + q.no + "%"}}
        ];
      }

      if (q && q.type) {
        type = `type=${q.type}&`;
      }

      let baseURL = `${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/ormas-asing?${type}&filter=${encodeURIComponent(JSON.stringify(params))}`;
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deletePenambahanAsing({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/ormas-asing/" + id

      let dataPenambahanOrmas = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity penambahan ormas tidak terdaftar
            // dataPenambahanOrmas.then(resp => {
            //   this.dispatch("storeActivity", {
            //     "activity": ConstActivity.ACT_UNREGISTERED_ORMAS_DELETE.replace("_ORMAS_", resp.data.data.nama_ormas),
            //     "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            //   })
            // })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    ciriKhususList({ commit },mode) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/ciri-khusus?filter=" + encodeURIComponent(JSON.stringify(mode))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    ciriKhususListCount({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/ciri-khusus/count"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitCiriKhusus({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/ciri-khusus"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store log activity ciri khusus
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_TRAIT_ADD.replace("_CIRI_KHUSUS_", form.namaCiriKhusus),
              "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchCiriKhusus({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/ciri-khusus/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store log activity ciri khusus
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_TRAIT_UPDATE.replace("_CIRI_KHUSUS_", form[0].namaCiriKhusus),
              "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteCiriKhusus({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/ciri-khusus/" + id

      let dataCiriKhusus = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store log activity ciri khusus
            dataCiriKhusus.then(data => {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_TRAIT_DELETE.replace("_CIRI_KHUSUS_", data.data.namaCiriKhusus),
                "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    instansiList({ commit }, filter) {
      var f = ''
      if (filter) f = filter
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-instansi" + f
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitInstansi({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-instansi"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store log activity instansi
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_INSTANCE_ADD.replace("_INSTANSI_", form.nama),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitFetchInstansi({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-instansi/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store log activity instansi
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_INSTANCE_UPDATE.replace("_INSTANSI_", form[0].nama),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteInstansi({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-instansi/" + id

      let dataInstansi = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store log activity instansi
            dataInstansi.then(data => {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_INSTANCE_DELETE.replace("_INSTANSI_", data.data.nama),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    jenisKegiatanList({ commit },mode) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-kegiatan?filter=" + encodeURIComponent(JSON.stringify(mode))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    jenisKegiatanListCount({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-kegiatan/count"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitJenisKegiatan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-kegiatan"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store activity jenis kegiatan
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_ACTIVITY_ADD.replace("_KEGIATAN_", form.namaJenisKegiatan),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchJenisKegiatan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-kegiatan/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store activity jenis kegiatan
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_ACTIVITY_UPDATE.replace("_KEGIATAN_", form[0].namaJenisKegiatan),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },    
    deleteJenisKegiatan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-kegiatan/" + id

      let dataJenisKeagiatan = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Store activity jenis kegiatan
            dataJenisKeagiatan.then(data => {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_ACTIVITY_DELETE.replace("_KEGIATAN_", data.data.namaJenisKegiatan),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },


    bidangKegiatanList({ commit }, mode) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-ormas?filter=" + encodeURIComponent(JSON.stringify(mode))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    bidangKegiatanListCount({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-ormas/count"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitBidangKegiatan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-ormas"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_FIELD_ADD.replace("_BIDANG_KEGIATAN_", form.namaJenisOrmas),
              "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchBidangKegiatan({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-ormas/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_FIELD_UPDATE.replace("_BIDANG_KEGIATAN_", form[0].namaJenisOrmas),
              "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteBidangKegiatan({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-ormas/" + id

      let dataBidangKegiatan = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity
            dataBidangKegiatan.then(data => {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_FIELD_DELETE.replace("_BIDANG_KEGIATAN_", data.data.namaJenisOrmas),
                "user_id": JSON.parse(localStorage.getItem("user")).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    sekretariatList({ commit }, mode) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat?filter=" + encodeURIComponent(JSON.stringify(mode))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    sekretariatListCount({ commit }) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat/count"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitSekretariat({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity add sekretariat
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_SECRETARY_ADD.replace("_SEKRETARIAT_", form.namaLokasi),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    patchSekretariat({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity add sekretariat
            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_SECRETARY_UPDATE.replace("_SEKRETARIAT_", form[0].namaLokasi),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteSekretariat({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/lokasi-sekretariat/" + id

      let dataSekretariat = axios({
        url: baseURL,
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
      })

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity add sekretariat
            dataSekretariat.then(data => {
              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_SECRETARY_DELETE.replace("_SEKRETARIAT_", data.data.namaLokasi),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getDokumenList({ commit }) {
      let params = {
        "where": {
          "is_syarat": true
        }
      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/jenis-dokumen?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    uploadDocument({ commit }, form) {
      let idormaspenambahan = localStorage.getItem('idPenambahanOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_FILE_URL + "/upload/syarat-manual/" + idormaspenambahan
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    uploadDocumentUmum({ commit }, form) {
      // let idormas = localStorage.getItem('idOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_FILE_URL + "/upload/umum"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    uploadFileGeneral({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_FILE_URL + "/upload/umum"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitDocument({ commit }, form) {
      let idormaspenambahan = localStorage.getItem('idPenambahanOrmas')
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/penambahan-ormas/dokumen/" + idormaspenambahan
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getNotifikasi({ commit }) {
      let sysuser = ''
      if (JSON.parse(localStorage.getItem('profile'))) {
        if(JSON.parse(localStorage.getItem('profile')).profilUser){
          sysuser = JSON.parse(localStorage.getItem('profile')).profilUser.sys_user_id
        } else {
          sysuser = JSON.parse(localStorage.getItem('profile')).id
        }
      }
      
      let params = {
        offset: 0,
        limit: 100,
        skip:0,
        order: "id desc",
        where: {sys_user_id: sysuser}
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/notifikasi?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getNotifikasiDetail({ commit },id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/notifikasi/" + id
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getCMSByParent({ commit }, parent_id) {
      let params = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        "where": {
          "status_tayang_id": 1
        }, 
        "include": [{"relation":"kategori"}]
      }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms-by-kategori-parent?id_parent_kategori=" + parent_id + "&filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getCMS({ commit }, mode) {
      // let params = {
      //   "where": {
      //     "is_syarat": true
      //   }
      // }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms?filter=" + encodeURIComponent(JSON.stringify(mode))
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getCMSCount({ commit }) {
      // let params = {
      //   "where": {
      //     "is_syarat": true
      //   }
      // }
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms/count"
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitCMS({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity content
            this.dispatch('storeActivity', {
              "activity": ConstActivity.ACT_CONTENT_ADD.replace("_KONTEN_", form.title),
              "user_id": JSON.parse(localStorage.getItem('profile')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getCMSDetail({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms/" + id
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteCMS({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchCMS({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/cms/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Log activity content
            this.dispatch('storeActivity', {
              "activity": ConstActivity.ACT_CONTENT_UPDATE.replace("_KONTEN_", form[0].title),
              "user_id": JSON.parse(localStorage.getItem('profile')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listKategoriPilihan({ commit}){
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kategori-konten-pilihan"
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listKategoriCMS({ commit}, mode){
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kategori-konten?filter=" + encodeURIComponent(JSON.stringify(mode))
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listKategoriCMSCount({ commit}){
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kategori-konten/count"
      // ?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitKategoriCMS({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kategori-konten"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchKategoriCMS({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kategori-konten/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deleteKategoriCMS({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/kategori-konten/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    getUsersCount({ commit}, q){
      let params = {
      }
      if(q.search && q.search != '') params.nama_lengkap = {"ilike": "%"+ q.search +"%"}
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/custom/count?where=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listUsers({ commit}, q){
      let params = {
        offset: q.offset * 10,
        "limit":10,
        "where": {
        },
        "include":[
          "masterKesbangpol",
          {
            "relation":"user",
            "scope":{
              "include":[
                {
                  "relation":"roleUsers",
                  "scope":{
                    "include":[{
                      "relation":"role"
                    }]
                  }
                }
              ]
            }
          }
        ]
      }
      if(q.search && q.search != '') params.where.nama_lengkap = {"ilike": "%"+ q.search +"%"}

      // Dont't show current user
      params.where.sys_user_id = {"neq": parseInt(JSON.parse(localStorage.getItem('user')).id.toString())}

      let queryParams = `filter=${encodeURIComponent(JSON.stringify(params))}`;

      if (q.instansi)
        queryParams += `&instansi=${q.instansi}`;

      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/custom?" + queryParams
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listPembekuanCount({ commit}, q){
      // let params = {"include": ["dataOrmas"]}
      let params = { where: { "sysUserId": {"neq": null}}, }
      if(q.search) params.where.namaOrmas = { ilike: `%${q.search}%` }
      if(q.sengketa) params.where.isSengketa = q.sengketa
      
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/pembekuan-user/count?where=" + encodeURIComponent(JSON.stringify(params.where))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listPembekuan({ commit}, q){
      // let params = {"include": ["dataOrmas"]}
      let params = { limit: 20, offset: q.offset * 20, where: {"sysUserId": {"neq": null}}, "include":["user", { relation: "masterLokasiSekretariat" }, {"relation": "sengketaOrmas", "scope": {"order": "id desc"}}]}
      if(q && q.search && q.search != '') params.where.namaOrmas = { ilike: `%${q.search}%` }
      if(q && q.sengketa && q.sengketa != '') params.where.isSengketa = q.sengketa == 1 ? true : false 
      
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/pembekuan-user?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    deactivateUser({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/pembekuan-user/deactivate/" + form.id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: {isActive: form.isActive},
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            let status = form.isActive ? "Buka Pembekuan" : "Pembekuan"
            const params = {
              limit: 1,
              where: {user_id: form.id},
            }
            let urlBispro = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/data-organisasi?filter=" + encodeURIComponent(JSON.stringify(params)) ;

            // Get data organisasi by id user Log activity pembekuan ormas
            axios({
              url: urlBispro,
              method: "GET",
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
            })
            .then(resp => {
              this.dispatch('storeActivity', {
                "activity": ConstActivity.ACT_FREEZE.replace("_ORMAS_", resp.data.data[0].namaOrmas).replace("_STATUS_", status),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              })
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitUsers({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/custom"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_USER_ADD.replace("_PENGGUNA_", form.nama_pengguna),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchUsers({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/custom/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            this.dispatch("storeActivity", {
              "activity": ConstActivity.ACT_USER_UPDATE.replace("_PENGGUNA_", form[0].nama_pengguna),
              "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
            })
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    changePasswordUsers({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/ubah-sandi/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            this.dispatch("detailUsers", form[1]).then(resp => {
              let user = resp.data
              let user_name = user.profilUser ? user.profilUser.nama_lengkap : user.username

              this.dispatch("storeActivity", {
                "activity": ConstActivity.ACT_USER_CHANGE_PASS.replace("_PENGGUNA_", user_name),
                "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
              })
            })

          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    changePasswordProfile({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/ubah-sandi/"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    
    deleteUsers({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    detailUsers({ commit}, id){
      let params = {"include": [{
        "relation": "profilUser", 
        "scope": {
          "include": [
            {"relation":"masterKesbangpol"}
          ]
        }}, 
        {"relation":"roleUsers", 
        "scope": {"include": [{"relation":"role"}]}}]}
      let baseURL = process.env.VUE_APP_APPLICATION_AUTH_URL + "/users/" + id + "?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },

    userTypeList({ commit }, mode) {
      var filter = ''
      if(mode.nonOrmas){
        let params = {}
        params = {
          offset: mode.offset * 20, 
          limit: 20,
          skip: 0,
          "where": {
            "role_code": {"neq": "ormas"}
          }
        }
        filter = "?filter=" + encodeURIComponent(JSON.stringify(params))
      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/user-type" + filter
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitUserType({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/user-type"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchUserType({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/user-type/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },    
    deleteUserType({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/user-type/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    userActivity({ commit }, id) {
      let params = {
        "offset": 0,
        "limit": 1000,
        "skip": 0,
        "order": "activity_date",
        "where": {
          "user_id": Number(id)
        }
      }
      let baseURL = process.env.VUE_APP_APPLICATION_AUDIT_URL + "/activitys?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    storeActivity({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_AUDIT_URL + "/activitys"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "POST",
          data: form,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },

    getBidangKegiatanRekap({ commit }, q) {
      let params = {
        "order": "id",
        "where": {
          "kode": q.kode
        }
      }

      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/bidang-kegiatan?filter=" + encodeURIComponent(JSON.stringify(params))

      if (q.tipe === 'provinsi') {
        baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/bidang-kegiatan/provinsi?filter=" + encodeURIComponent(JSON.stringify(params))
      } else if (q.tipe === 'all') {
        baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/bidang-kegiatan/all"
      }

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    rekapMap({ commit }, q) {
      // let params = {
      //   "where": {"tipe": {"inq": q.tipe}}
      // }
      q.kd.where.tipe = {"inq": q.tipe}
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/list-wilayah?filter=" + encodeURIComponent(JSON.stringify(q.kd))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    rekapTable({ commit }, params) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    rekapExportPDF({ commit }, params) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/export-pdf?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    rekapExportExcel({ commit }, params) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/export-excel?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    rekapDetail({ commit }, kode = '') {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/" + kode

      if (kode.length === 0) {
        baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/rekap-organisasi/all"
      }

      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    wilayahProvList({ commit }, q) {
      let params = {
        offset: q.offset * 20, 
        limit: 20,
         skip: 0,
        "where": {
          "nama": {"ilike": "%"+ q.search +"%"},
          "tipe": 1
        }
      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    wilayahProvKabListCount({ commit }, q) {
      let params = {
          "nama": {"ilike": "%"+ q.search +"%"},
          "tipe": q.tipe
      }
      
      if(typeof q.kode == "string") {
        params["kode"] = {"ilike": "%"+ q.kode +"%"}

      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah/count?where=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    wilayahKabList({ commit }, q) {
      let params = {
        offset: q.offset * 20, 
        limit: 20,
         skip: 0,
        "where": {
          "nama": {"ilike": "%"+ q.search +"%"},
          "kode": {"ilike": "%"+ q.kode +"%"},
          "tipe": 2
        }
      }
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitWilayah({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah"
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form,
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitPatchWilayah({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "PATCH",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },    
    deleteWilayah({ commit }, id) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah/" + id
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "DELETE",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    listHakAkses({ commit }, q) {
      let params = {
        "offset": 0,
        "limit": 1000,
        "where": {
        },
        "include": ["hakAkses", "masterKesbangpol"]
      }
      if(q.search != '') params.where.nama_lengkap = {"ilike": "%"+ q.search +"%"}
      if(q.instansi != '') params.where.m_kesbangpol_id = Number(q.instansi)
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/hak-akses?filter=" + encodeURIComponent(JSON.stringify(params))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    submitHakAkses({ commit }, form) {
      let baseURL = process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/hak-akses/" + form[1]
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          data: form[0],
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        })
          .then(resp => {
            resolve(resp);

            // Collect access which set true
            let access = []
            Object.keys(form[0]).forEach(el => {
              if (form[0][el] == true) {
                access.push(el.replaceAll("_", " "))
              }
            });

            // Store log activity if access exists
            if (access.length > 0) {
              this.dispatch("detailUsers", form[2]).then(resp => {
                let user = resp.data
                let user_name = user.profilUser ? user.profilUser.nama_lengkap : user.username
  
                this.dispatch("storeActivity", {
                  "activity": ConstActivity.ACT_SET_ACCESS.replace("_PENGGUNA_", user_name).replace("_HAK_AKSES_", access.join(", ")),
                  "user_id": JSON.parse(localStorage.getItem('user')).id.toString()
                })
              })
            }
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    },
    wilayahCustomFilter({ commit }, query) {
      let baseURL = process.env.VUE_APP_APPLICATION_MASTER_URL + "/wilayah?filter=" + encodeURIComponent(JSON.stringify(query))
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL,
          method: "GET",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            if (err.response) commit("errorHandler", err.response.status);
            reject(err);
          });
      });
    }
  }
});
