export default Object.freeze({
    ACT_LOGIN: 'Login Aplikasi',
    ACT_LOGOUT: 'Logout Aplikasi',
    ACT_CONFIRMATION : 'Persetujuan - Pengajuan Perubahan _ORMAS_ _STATUS_',
    ACT_REPORT_BRANCH : 'Lapor Cabang - Ubah Status Cabang _ORMAS_ _STATUS_',
    ACT_UNREGISTERED_ORMAS_ADD : 'Tambah Ormas Tidak Terdaftar - _ORMAS_',
    ACT_UNREGISTERED_ORMAS_UPDATE : 'Ubah Ormas Tidak Terdaftar - _ORMAS_',
    ACT_UNREGISTERED_ORMAS_DELETE : 'Hapus Ormas Tidak Terdaftar - _ORMAS_',
    ACT_DISPUTE_ADD : 'Tambah Sengketa - _ORMAS_',
    ACT_DISPUTE_UPDATE : 'Ubah Info Sengketa - _ORMAS_',
    ACT_DISPUTE_STATUS : 'Ubah Status Sengketa - _ORMAS_',
    ACT_DISPUTE_DELETE : 'Hapus Info Sengketa - _ORMAS_',
    ACT_USER_ADD : 'Tambah Pengguna - _PENGGUNA_',
    ACT_USER_CHANGE_PASS : 'Ganti Kata Sandi Pengguna - _PENGGUNA_',
    ACT_USER_UPDATE : 'Ubah Pengguna - _PENGGUNA_',
    ACT_INSTANCE_ADD : 'Tambah Instansi - _INSTANSI_',
    ACT_INSTANCE_UPDATE : 'Ubah Instansi - _INSTANSI_',
    ACT_INSTANCE_DELETE : 'Hapus Instansi - _INSTANSI_',
    ACT_SET_ACCESS : 'Kelola Hak Akses _PENGGUNA_ - _HAK_AKSES_',
    ACT_FREEZE : 'Pembekuan _ORMAS_ - _STATUS_',
    ACT_TRAIT_ADD : 'Tambah Ciri Khusus - _CIRI_KHUSUS_',
    ACT_TRAIT_UPDATE : 'Ubah Ciri Khusus - _CIRI_KHUSUS_',
    ACT_TRAIT_DELETE : 'Hapus Ciri Khusus - _CIRI_KHUSUS_',
    ACT_FIELD_ADD : 'Tambah Bidang Kegiatan - _BIDANG_KEGIATAN_',
    ACT_FIELD_UPDATE : 'Ubah Bidang Kegiatan - _BIDANG_KEGIATAN_',
    ACT_FIELD_DELETE : 'Hapus Bidang Kegiatan - _BIDANG_KEGIATAN_',
    ACT_ACTIVITY_ADD : 'Tambah Kegiatan - _KEGIATAN_',
    ACT_ACTIVITY_UPDATE : 'Ubah Kegiatan - _KEGIATAN_',
    ACT_ACTIVITY_DELETE : 'Hapus Kegiatan - _KEGIATAN_',
    ACT_SECRETARY_ADD : 'Tambah Sekretariat - _SEKRETARIAT_',
    ACT_SECRETARY_UPDATE : 'Ubah Sekretariat - _SEKRETARIAT_',
    ACT_SECRETARY_DELETE : 'Hapus Sekretariat - _SEKRETARIAT_',
    ACT_CONTENT_ADD : 'Tambah Konten - _KONTEN_',
    ACT_CONTENT_UPDATE : 'Ubah Konten - _KONTEN_'
})
