// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const UPDATE_ACCOUNT_ROLE = "updateUserRoleInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_ACCOUNT_ROLE = "setRoleInfo";

const state = {
  userPersonalInfo: {
    sys_user_id: null,
    file_foto: "media/users/default.jpg",
    identitas: "-",
    nama_lengkap: "Guest",
    m_kesbangpol_id: null,
    masterKesbangpol: {},
    email: "-",
    no_telp: "-"
  },
  userAccountInfo: {
    id: null,
    username: null,
    email: null
  },
  userRoleInfo: [
    {
      id: null,
      sys_role_id: null,
      sys_user_id: null,
      role: {}
    }
  ]
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.userPersonalInfo;
  },

  currentUserAccountInfo(state) {
    return state.userAccountInfo;
  },

  currentUserRole(state) {
    return state.userRoleInfo;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [UPDATE_ACCOUNT_ROLE](context, payload) {
    context.commit(SET_ACCOUNT_ROLE, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, userPersonalInfo) {
    state.userPersonalInfo = userPersonalInfo;
  },
  [SET_ACCOUNT_INFO](state, userAccountInfo) {
    state.userAccountInfo = userAccountInfo;
  },
  [SET_ACCOUNT_ROLE](state, userRoleInfo) {
    state.userRoleInfo = userRoleInfo;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
