export const SET_LOADING = "setLoading";

const state = {
  tableResultLoading: false
};

const getters = {
  tableResultLoading(state) {
    return state.tableResultLoading;
  }
};

const actions = {
  [SET_LOADING](state, payload) {
    state.commit(SET_LOADING, payload);
  }
};

const mutations = {
  [SET_LOADING](state, payload) {
    state.tableResultLoading = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
